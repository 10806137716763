// TypingIndicator.module.scss
.typingContainer {
  display: flex;
  align-items: center;
  font-family: Arial, sans-serif;
  background-color: #F2F4FB;

  padding: .5rem;
  width:90px;
  border-radius: 20px;
}

.typingText {
  margin-right: 10px;
  color:#1860A5;
}

.typingEllipsis {
  display: inline-flex; // Use inline-flex to align items horizontally
  align-items: center;
font-size: 20px;
margin-right: 3px;
  span {
    display: inline-block;
    font-weight: bold;
    animation: ellipsis 1.5s infinite;
    color: #1860A5;
  }

  span:nth-of-type(1) {
    animation-delay: 0s;
  }

  span:nth-of-type(2) {
    animation-delay: 0.3s;
  }

  span:nth-of-type(3) {
    animation-delay: 0.6s;
  }
}

@keyframes ellipsis {
  0% {
    opacity: 0;
  }
  20% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
