.imgContainer {
img {
    border-radius: 15px;
    max-width: 400px;
    max-height: 800px;
}
}
.actionContainer {
    background-color: gray;
    display: flex;
    // border: 1px solid white;
    padding: 1rem 0;
    border-radius: 15px;
    // width: 100px;
    gap: 1rem;
    justify-content: center;
    button {
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: center;
        // width: 100px;
        border-radius: 15px;
        padding: 7px 15px;
        &:first-child {
        font-size: 20px;
        }

    }
   
}
@media screen and (max-width: 768px) {
    .imgContainer {
        img {
            border-radius: 15px;
            max-width: 300px;
            max-height: 300px;
        }
        }

        .actionContainer {
            background-color: gray;
            display: flex;
            // border: 1px solid white;
            padding: .5rem 0;
            border-radius: 15px;
            // width: 100px;
            gap: 1rem;
            justify-content: center;
            button {
                cursor: pointer;
                display: flex;
                align-items: center;
                justify-content: center;
                // width: 100px;
                border-radius: 15px;
                padding: 7px 15px;
                &:first-child {
                font-size: 20px;
                }
        
            }
           
        }
}