//---------------------file server modal

.filePreviewModal {
    position: absolute; 
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%); 
    width: 20%;
    max-height: 50%; 
    background-color: #fff;
    border-radius: 15px;
    padding: 10px;
    display: flex;
    flex-direction: column;
    // justify-content: center;
    align-items: center;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    overflow: hidden;
    .closeBtn {
      text-align: right;
      width: 100%;
      cursor: pointer;
      margin-bottom: 1rem;
    }
  
    .filePreview {
      width: 80%;
      height: 70%;
      display: flex;
      justify-content: center;
      align-items: center;
      // background-color: #f0f0f0;
      border-radius: 10px;

  
      img {
        max-width: 100%;
        height: 300px;
        border-radius: 10px;
        border: 5px solid lightblue;
      }
  
      video {
        max-width: 100%;
        max-height: 300px;
        border-radius: 10px;
      }
    }
  }

  .inputArea {
    // border: 1px solid black;
    display: flex;
    background: #fff;
    padding: 1rem;
    position: sticky;

    textarea {
      flex: 1;
      padding: 0.5rem 2rem;
      border: 1px solid #ccc;
      border-radius: 40px;
      margin-right: 0.5rem;
      width: 80%;
      height: 2rem;
      resize: none;
      font-size: 1.2rem;
      background-color: #F2F4FB;
      
    }

    button {
      padding: 0; 
      width: 50px;
      height: 50px; 
      background: #1860A5;
      color: #fff;
      border: none;
      border-radius: 50%; 
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
  //----------responsive styles
@media screen and (max-width: 1024px) {
 
  .filePreviewModal {
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%); 
    width: 45%; 
    max-height: 45%;
  }
 
 }
 @media screen and (max-width: 992px) {
 
  .filePreviewModal {
   
    .filePreview {
      img {
        max-width: 100%;
        height: 200px;
        border-radius: 10px;
        border: 5px solid lightblue;
      }
      video {
        max-width: 100%;
        height: 200px;
        border-radius: 10px;
      }
    }
  }
 

 
 }
 @media screen and (max-width: 768px) {
 
  .filePreviewModal {
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 60%; 
    max-height: 60%; 
    .filePreview {
      img {
        max-width: 100%;
        height: 200px;
        border-radius: 10px;
        border: 5px solid lightblue;
      }
      video {
        max-width: 100%;
        height: 200px;
        border-radius: 10px;
      }
    }
  }
  
  
 
 }
 @media screen and (max-width: 450px) {
 
  .filePreviewModal {
    top: 45%;
    left: 50%;
    transform: translate(-50%, -50%); 
    width: 80%; 
    max-height: 58%; 
    .filePreview {
      img {
        max-width: 100%;
        height: 200px;
        border-radius: 10px;
        border: 5px solid lightblue;
      }
      video {
        max-width: 100%;
        height: 50%;
        border-radius: 10px;
      }
    }
  }
 
  .inputArea {
    // border: 1px solid black;
    display: flex;
    background: #fff;
    padding: 10px;
    

    textarea {
      flex: 1;
      padding: 0.5rem 2rem;
      border: 1px solid #ccc;
      border-radius: 40px;
      margin-right: 0.5rem;
      width: 80%;
      height: 2rem;
      resize: none;
      font-size: 1.2rem;
      &::placeholder{
        font-size: 15px;;
      }
    }    
  }
 
 }
