.dialBtn {
    all: unset;
    background-color: #10B981;
    border-radius: 50%;
    padding: 1.2rem;
    cursor: pointer;
    margin-left: 40px;
    animation: bounce 1s ;
    
    // margin: 0 auto;  
}
.dialBtnDisable{
   all: unset;
    background-color: #03cb2e71!important;
    border-radius: 50%;
    padding: 1.2rem;
    cursor: pointer;
    margin-left: 40px;
    // margin: 0 auto;
  }
  .modalContainer {
  background-color: #e6e8ec;
  width:250px;
  // height: 200px;
  padding: 10px;
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  
    p {
    text-align: left;
    color: #282829;
    font-size: 1.2rem;
    display: flex;
    align-items: center;
    gap:.5rem;
    }
    .okBtn {
      all: unset!important;
      background-color:#10B981!important ;
      padding: .6rem 1.5rem!important;
      border-radius: 15px!important;
      cursor: pointer!important;
      color: white!important;
      font-size: 1.3rem!important;
      
    }
  
  }

    @media screen and (max-width: 576px) {
    .dialBtn {
    all: unset;
    background-color: #03cb2f;
    border-radius: 50%;
    padding: .9rem 1rem;
    cursor: pointer;
    margin-left: 40px;
      transform: rotate(0deg) scale(1);
     animation: bounce 1s ;
    // margin: 0 auto;  
}
.dialBtnDisable{
   all: unset;
    background-color: #03cb2e71!important;
    border-radius: 50%;
    padding: .9rem 1rem;
    cursor: pointer;
    margin-left: 40px;
    // margin: 0 auto;
  }
  }
  @media screen and (max-width: 350px) {
    .dialBtn {
    all: unset;
    background-color: #03cb2f;
    border-radius: 50%;
    padding: 0.4rem .5rem;
    cursor: pointer;
    margin-left: 40px;
     animation: bounce 1s ;
    // margin: 0 auto;  
}
.dialBtnDisable{
   all: unset;
    background-color: #03cb2e71!important;
    border-radius: 50%;
    padding: 0.4rem .5rem;
    cursor: pointer;
    margin-left: 40px;
    // margin: 0 auto;
  }
  }


  @keyframes bounce {
  0% {
   opacity: 0;
   transform: scale(1.1)
  }
  // 50% {
  //   opacity: 0.5;
  //   transform: rotate(90deg) scale(1.2);
  
  // }
  100% {
    opacity: 1;
       transform:  scale(1);
     
  }
}